@font-face {
    font-family: 'DeliciousWeb';
    src: url('./delicious-heavy-webfont.woff2') format('woff2'),
         url('./delicious-heavy-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro-SemiBold.woff2') format('woff2'),
        url('SourceSansPro-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro-Light.woff2') format('woff2'),
        url('SourceSansPro-Light.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro-Bold.woff2') format('woff2'),
        url('SourceSansPro-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro-Regular.woff2') format('woff2'),
        url('SourceSansPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

